import { env } from "md-base-tools/env";
import { getCardFileList } from "@/api/index";
import { GoBack } from "@/utils/tools";
export default {
  data() {
    return {
      env,
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      page: 1,
      page_size: 10,
      total: 0
    };
  },
  created() {
    if (env.isWxApp()) {
      let query = {
        ...this.$route.query
      };
      const path = this.$route.path;
      let t = new Date().getTime();
      query.t = t;
      query.darkmode = true;
      this.$router.replace({
        path,
        query
      });
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // onRefresh() {
    //     this.refreshing = true
    //     this.finished = false
    //     this.getList()
    // },
    async getList() {
      this.loading = true;
      if (this.refreshing) {
        this.list = [];
        this.page = 1;
      }
      const {
        status,
        data
      } = await getCardFileList({
        page: this.page,
        page_size: this.page_size
      });
      if (status == 0 && data.list && data.list.length) {
        this.list.push(...data.list);
        this.page += 1;
        this.total = data.total;
      }
      if (this.list.length >= this.total || data.list && data.list.length == 0) {
        this.finished = true;
      } else {
        this.finished = false;
      }
      this.refreshing = false;
      this.loading = false;
    },
    goUserCollect(id) {
      this.$router.push({
        path: '/luckycards/fastsell/user_collect',
        query: {
          id: id
        }
      });
    },
    goBack() {
      GoBack();
    }
  }
};